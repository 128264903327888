<template>
  <div>
    <form-wizard
      color="#38B22D"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Basic details tab -->
      <tab-content
        title="Basic Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col md="6" class="mb-2">
              <h5 class="mb-0">Basic Details</h5>
              <small class="text-muted">
                Enter Employee Basic Details.
              </small>
            </b-col>
            <b-col md="6">
              <div class="demo-inline-spacing">
                <b-form-checkbox v-model="data_local.status" switch inline>
                  Status
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label class="required-l" for="name">Name</label>
                  <b-form-input
                    id="name"
                    v-model="data_local.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Employee Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <label class="required-l" for="email">Email</label>
                  <b-form-input
                    id="email"
                    v-model="data_local.email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="employee@mail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="employee id"
                  rules="required"
                >
                  <label class="required-l" for="employee-id"
                    >Employee Id</label
                  >
                  <b-form-input
                    id="employee-id"
                    v-model="data_local.employee_id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Employee Id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Gender" label-for="last-name">
                <div class="demo-inline-spacing">
                  <b-form-radio v-model="data_local.gender" value="male">
                    Male
                  </b-form-radio>
                  <b-form-radio v-model="data_local.gender" value="female">
                    Female
                  </b-form-radio>
                  <b-form-radio v-model="data_local.gender" value="other">
                    Other
                  </b-form-radio>
                </div>
              </b-form-group></b-col
            >

            <b-col md="6">
              <b-form-group label="Date of Birth" label-for="date-of-birth">
                <flat-pickr
                  v-model="data_local.date_of_birth"
                  :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                  class="form-control"
                  name="Date of Birth"
                  placeholder="Select Date.."
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Mobile" label-for="mobile">
                <validation-provider
                  #default="{ errors }"
                  name="mobile"
                  rules="numeric"
                >
                  <b-form-input
                    id="mobile"
                    v-model="data_local.mobile"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Mobile Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Emergency Contact Name"
                label-for="emergency-contact-name"
              >
                <b-form-input
                  id="emergency-contact-name"
                  v-model="data_local.emergency_contact_name"
                  type="text"
                  placeholder="Emergency Contact Name"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Emergency Contact Number"
                label-for="emergency-contact-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="emergency contact number"
                  rules="numeric"
                >
                  <b-form-input
                    id="emergency-contact-number"
                    v-model="data_local.emergency_contact_number"
                    type="text"
                    placeholder="Emergency Contact Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Reporting Manager"
                label-for="reporting manager"
              >
                <v-select
                  v-model="data_local.reporting_manager"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="reportingManagerOptions"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="true"
                  input-id="reporting-manager"
                  placeholder="Select Employee.."
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Confirmation Date"
                label-for="confirmation date"
              >
                <flat-pickr
                  v-model="data_local.confirmation_date"
                  :config="{ dateFormat: 'Y-m-d' }"
                  class="form-control"
                  name="Confirmation Date"
                  placeholder="Select Date.."
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="date of joining"
                  >Date Of Joining</label
                >
                <flat-pickr
                  v-model="data_local.date_of_join"
                  :config="{ dateFormat: 'Y-m-d' }"
                  class="form-control"
                  name="Date of Join"
                  placeholder="Select Date.."
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Probation Period (Days)"
                label-for="probation period"
              >
                <validation-provider
                  #default="{ errors }"
                  name="probation period"
                  rules="numeric"
                >
                  <b-form-input
                    id="probation-period"
                    v-model="data_local.probation_days"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Probation Period in Days"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="employee_type"
                  >Employee Type</label
                >
                <v-select
                  v-model="data_local.employee_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="list_employee_types"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="employee_type"
                  placeholder="Select Employee Type.."
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Father Name" label-for="father-name">
                <b-form-input
                  id="father-name"
                  v-model="data_local.father_name"
                  placeholder="Father Name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Spouse Name" label-for="spouse-name">
                <b-form-input
                  id="spouse-name"
                  v-model="data_local.spouse_name"
                  type="text"
                  placeholder="Spouse Name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Position tab -->
      <tab-content
        title="Position"
        icon="feather icon-user"
        :before-change="validationForm"
      >
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Position</h5>
              <small class="text-muted">Enter Employee Position Info.</small>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="roles">Roles</label>
                <v-select
                  v-model="data_local.role_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="list_roles"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="role"
                  placeholder="Select Role.."
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <label class="mr-sm-2 required-l" for="designation"
                >Designation
                <b-button
                  v-b-modal.designation-modal
                  size="sm"
                  variant="outline-light"
                  class="border-0 p-0"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                    class="text-warning"
                /></b-button>
              </label>
              <v-select
                v-model="data_local.designation_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="designationOptions"
                :reduce="(label) => label.code"
                label="label"
                :clearable="false"
                :searchable="true"
                input-id="designation"
                value.sync="designation_id"
                placeholder="Select Designation.."
              />
            </b-col>
            <b-col md="6">
              <label class="mr-sm-2 required-l" for="division"
                >Division
                <b-button
                  v-b-modal.division-modal
                  size="sm"
                  variant="outline-light"
                  class="border-0 p-0"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                    class="text-warning"
                /></b-button>
              </label>
              <v-select
                v-model="data_local.division_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="divisionOptions"
                :reduce="(label) => label.code"
                label="label"
                :clearable="false"
                :searchable="true"
                input-id="division"
                value.sync="division_id"
                placeholder="Select Division.."
              />
            </b-col>
            <b-col md="6">
              <label class="mr-sm-2 required-l" for="department"
                >Department
                <b-button
                  v-b-modal.department-modal
                  size="sm"
                  variant="outline-light"
                  class="border-0 p-0"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                    class="text-warning"
                /></b-button>
              </label>
              <v-select
                v-model="data_local.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departmentOptions"
                :reduce="(label) => label.code"
                label="label"
                :clearable="false"
                :searchable="true"
                input-id="department"
                value.sync="department_id"
                placeholder="Select Department.."
              />
            </b-col>
            <b-col md="6">
              <label class="mr-sm-2 required-l" for="Communication Channel ID"
                >Communication Channel Type
              </label>
              <v-select
                v-model="data_local.channel_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="channelOptions"
                :reduce="(label) => label.code"
                label="label"
                :clearable="false"
                :searchable="true"
                input-id="channel_type"
                value.sync="channel_type"
                placeholder="Select Communication Channel.."
              />
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Communication Channel ID"
                label-for="communication-channel"
              >
                <b-form-input
                  id="communication-channel"
                  v-model="data_local.channel_id"
                  type="text"
                  placeholder="Communication Channel ID"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group >
                <label class="required-l" for="shift"
                  >Shifts</label
                >
                <v-select
                  v-model="data_local.shift_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="list_shifts"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="true"
                  input-id="shift"
                  value.sync="shift"
                  placeholder="Select Shift.."
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- PF,ESI&LWF -->
      <tab-content
      title="PF,ESI&LWF"
      icon="feather icon-user"
      :before-change="validationForm"
      >
      <validation-observer ref="infoRules" tag="form">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">PF,ESI and LWF</h5>
            <small class="text-muted">Enter Employee PF,ESI and LWF Info.</small>
          </b-col>

          <b-col md="6">
            <b-form-group label="PAN Number" label-for="pan_number">
              <validation-provider
                #default="{ errors }"
                name="PAN NUmber"
              >
                <b-form-input
                  id="pan_number"
                  v-model="data_local.pan_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="PAN Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Include Number" label-for="last-name">
              <div class="demo-inline-spacing">
                <b-form-radio v-model="data_local.include_number" value="PF">
                  Include PF
                </b-form-radio>
                <b-form-radio v-model="data_local.include_number" value="ESI">
                  Include ESI
                </b-form-radio>
                <b-form-radio v-model="data_local.include_number" value="LWF">
                  Include LWF
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="data_local.include_number && data_local.include_number == 'PF' ">
            <b-form-group label="PF Number" label-for="pf_number">
              <validation-provider
                #default="{ errors }"
                name="PF Number"
              >
                <b-form-input
                  id="pf_number"
                  v-model="data_local.pf_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="PF Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="data_local.include_number && data_local.include_number == 'ESI' ">
            <b-form-group label="ESI Number" label-for="esi_number">
              <validation-provider
                #default="{ errors }"
                name="ESI Number"
              >
                <b-form-input
                  id="esi_number"
                  v-model="data_local.esi_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ESI Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="data_local.include_number && data_local.include_number == 'LWF' ">
            <b-form-group label="LWF Number" label-for="lwf_number">
              <validation-provider
                #default="{ errors }"
                name="LWF Number"
              >
                <b-form-input
                  id="lwf_number"
                  v-model="data_local.lwf_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="LWF Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="UAN Number" label-for="uan_number">
              <validation-provider
                #default="{ errors }"
                name="UAN Number"
              >
                <b-form-input
                  id="uan_number"
                  v-model="data_local.uan_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="UAN Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="PF Excess Contribution" label-for="last-name">
              <div>
                <b-form-radio v-model="data_local.pf_excess_contribution" value="employee_and_employer">
                  Employee & Employer contribution 
                </b-form-radio>
                <b-form-radio v-model="data_local.pf_excess_contribution" value="only_employee">
                  Employee contribution 
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      </tab-content>
      <!-- Address tab -->
      <tab-content title="Address Info" icon="feather icon-map-pin">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Address Info</h5>
            <small class="text-muted">Enter Employee Address Info.</small>
          </b-col>
          <h5 class="col-12 d-flex justify-content-between">
            <div>Current Address</div>
          </h5>
          <b-col md="6">
            <b-form-group label="Address Line 1" label-for="address-line-1">
              <b-form-input
                id="address-line-1"
                v-model="data_local.location.add_line_1"
                type="text"
                placeholder="Address Line 1"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Address Line 2" label-for="address-line-2">
              <b-form-input
                id="address-line-2"
                v-model="data_local.location.add_line_2"
                type="text"
                placeholder="Address Line 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Postcode" label-for="postcode">
              <b-form-input
                id="postcode"
                v-model="data_local.location.post_code"
                type="text"
                placeholder="Postcode"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="data_local.location.city"
                type="text"
                placeholder="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="State" label-for="state">
              <b-form-input
                id="state"
                v-model="data_local.location.state"
                type="text"
                placeholder="State"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Country" label-for="country">
              <b-form-input
                id="country"
                v-model="data_local.location.country"
                type="text"
                placeholder="Country"
              />
            </b-form-group>
          </b-col>
          <h5 class="col-12 d-flex justify-content-between">
            <div>Permanent Address</div>
            <div>
              Same as Current Address
              <b-button
                type="button"
                variant="primary"
                size="sm"
                @click="copyLocation"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </div>
          </h5>
          <b-col md="6">
            <b-form-group
              label="Address Line 1"
              label-for="permanent-address-address-line-1"
            >
              <b-form-input
                id="permanent-address-address-line-1"
                v-model="permanent_address.add_line_1"
                type="text"
                placeholder="Address Line 1"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Address Line 2"
              label-for="permanent-address-address-line-2"
            >
              <b-form-input
                id="permanent-address-address-line-2"
                v-model="permanent_address.add_line_2"
                type="text"
                placeholder="Address Line 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Postcode"
              label-for="permanent-address-postcode"
            >
              <b-form-input
                id="permanent-address-postcode"
                v-model="permanent_address.post_code"
                type="text"
                placeholder="Postcode"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="City" label-for="permanent-address-city">
              <b-form-input
                id="permanent-address-city"
                v-model="permanent_address.city"
                type="text"
                placeholder="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="State" label-for="permanent-address-state">
              <b-form-input
                id="permanent-address-state"
                v-model="permanent_address.state"
                type="text"
                placeholder="State"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Country" label-for="permanent-address-country">
              <b-form-input
                id="permanent-address-country"
                v-model="permanent_address.country"
                type="text"
                placeholder="Country"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- checklist  -->
      <tab-content title="Checklist" icon="feather icon-check-circle">
        <b-row>
          <b-col class="md-8" />
          <b-col class="md-4">
            <b-form autocomplete="off" class="faq-search-input">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  placeholder="Search Checklist..."
                />
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>

        <section id="faq-tabs" class="mt-2 mb-2">
          <div v-if="faqData.length">
            <b-tabs
              vertical
              content-class="col-12 col-md-8 col-lg-9"
              pills
              nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
              nav-class="nav-left"
            >
              <b-tab
                v-for="(obj, index) in faqData"
                :key="index"
                :active="!index"
              >
                <!-- title -->
                <template #title>
                  <span class="font-weight-bold">{{ obj.title }}</span>
                </template>

                <div v-if="obj">
                  <div class="d-flex align-items-center mt-1 mb-2">
                    <div>
                      <h4 class="mb-0">
                        {{ obj.title }}
                      </h4>
                    </div>
                  </div>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(data, indexes) in obj.descriptions"
                      :key="indexes"
                    >
                      <b-form-checkbox
                        v-model="data_local.checklist_description_ids"
                        :value="data.hashid"
                        >{{ data.description }}</b-form-checkbox
                      ></b-list-group-item
                    >
                  </b-list-group>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-else class="text-center">
            <span>No Checklist Found</span>
          </div>
        </section>
      </tab-content>
    </form-wizard>

    <b-modal
      id="designation-modal"
      ref="modal"
      size="md"
      title="Submit Your Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDesignation"
    >
      <template #modal-header="{ close }">
        <h5>Add Designation</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <!-- <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col cols="2"> # </b-col>
            <b-col cols="4"> Designation </b-col>
            <b-col cols="6" class="text-center"> Action </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          v-for="(item, index) in designationOptions"
          :key="item.id"
        >
          <b-row>
            <b-col cols="2">
              {{ ++index }}
            </b-col>
            <b-col cols="4">
              {{ item.label }}
            </b-col>
            <b-col cols="6" class="text-center">
              <b-button
                variant="danger"
                size="sm"
                @click="removeSource(item)"
              >
                <feather-icon icon="Trash2Icon" size="16" />
                <span class="text-nowrap">Remove</span>
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group> -->
      <form ref="form" @submit.stop.prevent="addDesignation" class="mt-1">
        <b-form-group
          label="Designation Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="designationNameState"
        >
          <b-form-input
            id="name-input"
            v-model="designationName"
            :state="designationNameState"
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="division-modal"
      ref="modal"
      size="md"
      title="Submit Division Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDivision"
    >
      <template #modal-header="{ close }">
        <h5>Add Division</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <form ref="form" @submit.stop.prevent="addDivision" class="mt-1">
        <b-form-group
          label="Division Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="divisionNameState"
        >
          <b-form-input
            id="name-input"
            v-model="divisionName"
            :state="divisionNameState"
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="department-modal"
      ref="modal"
      size="md"
      title="Submit Department Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDepartment"
    >
      <template #modal-header="{ close }">
        <h5>Add Department</h5>
        <b-button size="sm" variant="outline-danger" @click="close()">
          <feather-icon class="cursor-pointer" icon="XIcon" />
        </b-button>
      </template>
      <form ref="form" @submit.stop.prevent="addDepartment" class="mt-1">
        <b-form-group
          label="Department Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="departmentNameState"
        >
          <b-form-input
            id="name-input"
            v-model="departmentName"
            :state="departmentNameState"
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()"> Save </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import employeeStoreModule from "../employeeStoreModule";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.data_local.name = this.$route.query.name;
    if (this.$route.query.dob) {
      this.data_local.date_of_birth = new Date(
        Date.parse(this.$route.query.dob)
      ).toISOString();
    }
    if (this.$route.query.number) {
      this.data_local.mobile = this.$route.query.number;
    }
  },
  data() {
    return {
      required,
      email,
      numeric,
      faqSearchQuery: "",
      faqData: {},
      reportingManagerOptions: [],
      data_local: {
        name: "",
        email: "",
        status: true,
        avatar: "assets/images/dummy-avatar.png",
        employee_id: "",
        reporting_manager: "",
        date_of_join: "",
        probation_days: "",
        role_id: "",
        confirmation_date: "",
        date_of_birth: "",
        mobile: "",
        gender: "male",
        emergency_contact_name: "",
        emergency_contact_number: "",
        father_name: "",
        spouse_name: "",
        designation_id: "",
        location: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "India",
        },
        employee_type: "",
        checklist_description_ids: [],
      },
      list_roles: [],
      designationOptions: [],
      divisionOptions: [],
      departmentOptions: [],
      designationName: "",
      designationNameState: null,
      divisionName: null,
      divisionNameState: null,
      departmentName: null,
      departmentNameState: null,
      list_shifts: [],
      list_employee_types: [
        { label: "Permanent", code: "permanent" },
        { label: "Contract", code: "contract" },
      ],
      channelOptions:[
        { label: "Slack", code: "slack" },
        { label: "Skype", code: "skype" },
        { label: "Microsoft Teams", code: "teams" },
        { label: "Email", code: "email" },
      ],
      permanent_address: {
        add_line_1: "",
        add_line_2: "",
        post_code: "",
        city: "",
        state: "",
        country: "",
      },
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-employees";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  created() {
    this.fetchRolesList();
    this.fetchDesignationsList();
    this.fetchDivisionsList();
    this.fetchDepartmentsList();
    this.fetchShiftsList();
  },
  methods: {
    copyLocation() {
      this.permanent_address.add_line_1 = this.data_local.location.add_line_1;
      this.permanent_address.add_line_2 = this.data_local.location.add_line_2;
      this.permanent_address.city = this.data_local.location.city;
      this.permanent_address.state = this.data_local.location.state;
      this.permanent_address.country = this.data_local.location.country;
      this.permanent_address.post_code = this.data_local.location.post_code;
    },
    resetModal() {
      this.designationName = "";
    },
    fetchShiftsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchShiftsList")
        .then((response) => {
          this.list_shifts = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    addDesignation() {
      const self = this;
      let params = [];
      params.name = self.designationName;
      this.$store
        .dispatch("app-employees/addDesignation", params)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Designation Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.fetchDesignationsList();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Designation Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    addDivision() {
      const self = this;
      let params = [];
      params.name = self.divisionName;
      this.$store
        .dispatch("app-employees/addDivision", params)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Division Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.fetchDivisionsList();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Division Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    addDepartment() {
      const self = this;
      let params = [];
      params.name = self.departmentName;
      this.$store
        .dispatch("app-employees/addDepartment", params)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Department Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.fetchDepartmentsList();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Department Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    removeDesignation(designation) {
      const self = this;
      let params = [];
      params.id = designation.id;
      this.$store
        .dispatch("app-employees/removeDesignation", params)
        .then((res) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Designation Removed",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.fetchCandidateDesignation();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Designation Removing Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    removeDivision(division) {
      const self = this;
      let params = [];
      params.id = division.id;
      this.$store
        .dispatch("app-employees/removeDivision", params)
        .then((res) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Division Removed",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.fetchDivisionsList();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Division Removing Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    removeDepartment(department) {
      const self = this;
      let params = [];
      params.id = department.id;
      this.$store
        .dispatch("app-employees/removeDepartment", params)
        .then((res) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Department Removed",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.fetchDepartmentsList();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Division Removing Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    fetchRolesList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchRolesList")
        .then((response) => {
          this.list_roles = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchDesignationsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDesignationsList")
        .then((response) => {
          this.designationOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchDivisionsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDivisionList")
        .then((response) => {
          this.divisionOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetchDepartmentsList() {
      this.isloading = true;
      this.$store
        .dispatch("app-employees/fetchDepartmentList")
        .then((response) => {
          this.departmentOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    formSubmitted() {
      const self = this;
      this.data_local.permanent_address = self.permanent_address;
      this.$store
        .dispatch("app-employees/addEmployee", this.data_local)
        .then((res) => {
          if (res.data.success) {
            if (this.$route.query.id) this.updateStatus(this.$route.query.id);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Employee Added",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            this.$router.push("/employees");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Employee Adding Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Employee Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchData() {
      this.$http
        .get("/checklistform", { params: { q: this.faqSearchQuery } })
        .then((res) => {
          this.faqData = res.data.data;
        });
      this.$http.get("/list/employees").then((res) => {
        this.reportingManagerOptions = res.data.data;
      });
    },
    updateStatus(id) {
      this.$store
        .dispatch("app-employees/updateCandidateStatus", {
          id: id,
        })
        .then((res) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-faq.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
